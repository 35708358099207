import {Injectable} from '@angular/core';
import {IClientEvent, ServiceBase} from '@tni/core';
import {IClientSession} from '../../../../projects/@tni/core/src/lib/logger/clientSession.interface';
import {Observable, of} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ILoggerSessionFilter} from './ILoggerSessionFilter';
import {forEach} from '@angular/router/src/utils/collection';
import {ILoggerEventFilter} from './ILoggerEventFilter';

@Injectable()
export class LoggerReportService {
	private readonly _baseUrl: string = ServiceBase + '/api/logs/clientevents/v1/';

	constructor(private _httpClient: HttpClient) {}

	public getSessions(filter?: ILoggerSessionFilter): Observable<IClientSession[]> {
		return this._httpClient.get<IClientSession[]>(this._baseUrl + 'sessions', {params: this._toHttpParams(filter)});
	}

	public getEvents(sessionId: string, filter?: ILoggerEventFilter): Observable<IClientEvent[]> {
		if (filter.sessionId && filter.sessionId !== sessionId) {return of([]); }
		return this._httpClient.get<IClientEvent[]>(this._baseUrl + 'session/' + sessionId + '/events', {params: this._toHttpParams(filter)});
	}

	private _toHttpParams(o: object): {[prop: string]: string} {
		let p: {[prop: string]: string} = {};
		if (!o || !(Object.keys(o).length > 0)) {
			return p;
		}

		Object.keys(o).forEach((key: string) => {
			if (o[key]) {
				p[key] = o[key].toString();
			}
		});

		return p;
	}
}
