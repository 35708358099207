
import {tap} from 'rxjs/operators';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {JsonWebToken} from '@tni/core';
import {Observable} from 'rxjs';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	constructor(private _token: JsonWebToken) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!this._token.IsExpired()) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${this._token.GetToken()}`
				}
			});
		}
		return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
			if (event instanceof HttpResponse) {
				if (event.headers.has('set-token')) {
					this._token.SetToken(event.headers.get('set-token'));
				}
			}
		}, (err: any) => {
			if (err instanceof HttpErrorResponse) {
				if (err.status === 401 || err.status === 498) {
					this._token.Expire();
				}
			}
		}));
	}
}
