import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {map, shareReplay} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {JsonWebToken} from '@tni/core';

@Component({
	templateUrl: 'ContactCenterAgentAdminTemplate.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactCenterAdministrationConsoleWidget implements OnInit {
	public agentIdObs: Observable<number>;

	constructor(private _jwt: JsonWebToken, private _activatedRoute: ActivatedRoute) {}


	ngOnInit(): void {
		if (!(this._jwt.CanAction('ManageBu', 4018) || this._jwt.CanAction('ManageAll', 4018) || this._jwt.CanAction('ObserveBu', 4018))) {
			throw new Error('Forbidden: You do not have permission to manage the contact center.');
		}

		this.agentIdObs = this._activatedRoute.paramMap
			.pipe(
				map((params: ParamMap) => {
					let agentId: string = params.get('agentId');
					return !isNaN(agentId as any) && Number(agentId);
				}),
				shareReplay()
			);
	}
}
