import {Component, OnInit} from '@angular/core';
import {AppErrorHandler, JsonWebToken} from '@tni/core';
import {NavigationStart, Router, RouterEvent} from '@angular/router';
import {filter} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
	selector: 'portal',
	templateUrl: 'templates/portal.template.html'
})
export class AppComponent implements OnInit {
	constructor(public _token: JsonWebToken, private _router: Router) {}

	private _routerSubscription: Subscription;

	ngOnInit(): void {
		this._routerSubscription = this._router.events.pipe(
			filter((ev: RouterEvent) => ev instanceof NavigationStart)
		).subscribe(() => AppErrorHandler.clearEvents());
	}

	ngOnDestroy(): void {
		if (this._routerSubscription) {this._routerSubscription.unsubscribe(); }
	}
}
