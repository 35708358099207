import {Component} from '@angular/core';
import {LoggerReportService} from './LoggerReportService';
import {Observable} from 'rxjs';
import {ILoggerSessionFilter} from './ILoggerSessionFilter';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {debounceTime, distinctUntilChanged, map, shareReplay, take} from 'rxjs/operators';
import {ILoggerEventFilter} from './ILoggerEventFilter';

@Component({
	templateUrl: './LoggerReportTemplate.html',
	providers: [LoggerReportService]
})
export class LoggerReportWidget {
	public sessionFilterObs: Observable<ILoggerSessionFilter>;
	public eventFilterObs: Observable<ILoggerEventFilter>;

	constructor(private _route: ActivatedRoute) {}

	ngOnInit(): void {
		let queryParamsObs: Observable<ParamMap> = this._route.queryParamMap.pipe(
			debounceTime(10),
			shareReplay(1)
		);

		this.sessionFilterObs = queryParamsObs.pipe(
			map((params: ParamMap) => {
				let filter: ILoggerSessionFilter = {};

				filter.sessionId = params.get('session_sessionId');
				filter.browserName = params.get('session_browserName');
				filter.browserVersion = params.get('session_browserVersion');
				filter.operatingSystem = params.get('session_operatingSystem');
				filter.userAgent = params.get('session_userAgent');
				filter.clientSessionId = params.get('session_clientSessionId');
				filter.clientIp = params.get('session_clientIp');
				filter.employeeId = params.get('session_employeeId');
				filter.sessionCreateTimeStart = params.get('session_sessionCreateTimeStart');
				filter.sessionCreateTimeEnd = params.get('session_sessionCreateTimeEnd');

				return filter;
			}),
			distinctUntilChanged((a: ILoggerSessionFilter, b: ILoggerSessionFilter) => {
				if (!a && !b) {return true; }
				if (!a || !b) {return false; }
				return a.sessionId === b.sessionId &&
					a.browserName === b.browserName &&
					a.browserVersion === b.browserVersion &&
					a.operatingSystem === b.operatingSystem &&
					a.userAgent === b.userAgent &&
					a.clientSessionId === b.clientSessionId &&
					a.clientIp === b.clientIp &&
					a.employeeId === b.employeeId &&
					a.sessionCreateTimeStart === b.sessionCreateTimeStart &&
					a.sessionCreateTimeEnd === b.sessionCreateTimeEnd;
			}),
			shareReplay(1)
		);

		this.eventFilterObs = queryParamsObs.pipe(
			map((params: ParamMap) => {
				let filter: ILoggerEventFilter = {};

				filter.eventId = params.get('event_eventId');
				filter.sessionId = params.get('event_sessionId');
				filter.eventTimeStart = params.get('event_eventTimeStart');
				filter.eventTimeEnd = params.get('event_eventTimeEnd');
				filter.eventName = params.get('event_eventName');
				filter.levelId = params.get('event_levelId');
				filter.serviceName = params.get('event_serviceName');
				filter.clientEventId = params.get('event_clientEventId');
				filter.clientUrl = params.get('event_clientUrl');

				return filter;
			}),
			distinctUntilChanged((a: ILoggerEventFilter, b: ILoggerEventFilter) => {
				if (!a && !b) {return true; }
				if (!a || !b) {return false; }
				return a.eventId === b.eventId &&
					a.sessionId === b.sessionId &&
					a.eventTimeStart === b.eventTimeStart &&
					a.eventTimeEnd === b.eventTimeEnd &&
					a.eventName === b.eventName &&
					a.levelId === b.levelId &&
					a.serviceName === b.serviceName &&
					a.clientEventId === b.clientEventId &&
					a.clientUrl === b.clientUrl;
			}),
			shareReplay(1)
		);
	}
}
