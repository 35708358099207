import {AppErrorHandler, CanActivateJwt, CoreModule, EmployeeService, JsonWebToken} from '@tni/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {PhoneWidget} from './Widget/Phone/PhoneWidget';
import {HttpClientModule} from '@angular/common/http';
import {PhoneModule} from '@tni/phone';
import {LoggerReportWidget} from './Widget/LoggerReport/LoggerReportWidget';
import {LoggerReportSessionListComponent} from './Widget/LoggerReport/LoggerReportSessionListComponent';
import {LoggerReportEventListComponent} from './Widget/LoggerReport/LoggerReportEventListComponent';
import {ContactCenterAdministrationConsoleWidget} from './Widget/ContactCenterAgentAdmin/ContactCenterAgentAdminComponent';

@NgModule({
	imports: [BrowserModule, HttpClientModule, FormsModule, ReactiveFormsModule, RouterModule, PhoneModule, CoreModule],
	providers: [
		JsonWebToken,
		CanActivateJwt,
		{
			provide: ErrorHandler,
			useClass: AppErrorHandler
		},
		EmployeeService,
	],

	declarations: [
		PhoneWidget,
		LoggerReportWidget,
		LoggerReportSessionListComponent,
		LoggerReportEventListComponent,
		ContactCenterAdministrationConsoleWidget,
	],

	exports: [
		PhoneWidget,
		LoggerReportWidget,
		ContactCenterAdministrationConsoleWidget,
	]
})
export class WidgetsModule {
}

