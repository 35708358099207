
		<div (click)="refreshEventList()">Last updated: {{lastUpdateObs | async}}</div>
		<table class="table table-hover table-sortable minimal-margin-bottom table-striped-double table-fixed">
			<thead>
			<tr>
				<th class="time-column">Event Time</th>
				<th class="event-column">Event Name</th>
				<th class="level-column">Level</th>
				<th class="service-column">Service Name</th>
				<th class="url-column">URL</th>
				<th class="details-column">Details</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let clientEvent of eventListObs | async" tni-collapse-wrapper>
				<tr>
					<td class="time-column">{{clientEvent.EventTime}}</td>
					<td class="event-column">{{clientEvent.EventName}}</td>
					<td class="level-column">{{logLevel[clientEvent.LevelId]}}</td>
					<td class="service-column">{{clientEvent.ServiceName}}</td>
					<td class="url-column">{{clientEvent.ClientUrl}}</td>
					<td class="details-column clickable" tni-collapse-toggle>Show Details</td>
				</tr>
				<tr class="no-hover">
					<td colspan="6" class="no-padding">
						<div class="collapsible-sub-box" *tniCollapsibleIf="let colIf">
							<pre [innerHTML]="clientEvent.DetailsStr"></pre>
						</div>
					</td>
				</tr>
			</ng-container>
			</tbody>
		</table>
	