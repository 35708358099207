import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {UrlSerializer} from '@angular/router';
import {AppComponent} from './Components/AppComponent';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {WidgetsModule} from './WidgetsModule';
import {JwtInterceptor} from './Provider/JwtInterceptor';
import {LowerCaseUrlSerializer, RoutesModule} from './RoutesModule';
import {AppErrorHandler, ServiceWorkerControllerService} from '@tni/core';
import {LaunchComponent} from './Components/LaunchComponent';
import {CanActivateJwt, JsonWebToken, CoreModule} from '@tni/core';

@NgModule({
	imports: [BrowserModule, HttpClientModule, FormsModule, ReactiveFormsModule, WidgetsModule, RoutesModule, CoreModule],
	declarations: [AppComponent, LaunchComponent],
	bootstrap: [AppComponent],
	providers: [
		ServiceWorkerControllerService,
		JsonWebToken,
		CanActivateJwt,
		{
			provide: ErrorHandler,
			useClass: AppErrorHandler
		},
		{
			provide: UrlSerializer,
			useClass: LowerCaseUrlSerializer
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: JwtInterceptor,
			multi: true
		}
	]
})
export class AppModule {
}
