
		<div (click)="refreshSessionList()">Last updated: {{lastUpdateObs | async}}</div>
		<table class="table table-hover table-sortable minimal-margin-bottom table-fixed table-striped-double">
			<thead>
			<tr>
				<th class="time-column">Create Time</th>
				<th class="id-column">Session ID</th>
				<th class="employee-column">Employee</th>
				<th class="ip-column">IP</th>
				<th class="ua-column">UA</th>
				<th class="client-id-column">Client Session ID</th>
				<th class="events-column">Events</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let session of sessionListObs | async" tni-collapse-wrapper>
				<tr>
					<td class="time-column">{{session?.CreateTime}}</td>
					<td class="id-column">{{session?.Id}}</td>
					<td class="employee-column">{{(session?.EmployeeId | employee)}}, ({{session?.EmployeeId}})</td>
					<td class="ip-column">{{session?.ClientIp}}</td>
					<td class="ua-column">{{session?.UserAgent}}</td>
					<td class="client-id-column">{{session?.ClientSessionId}}</td>
					<td class="events-column clickable" tni-collapse-toggle>Show Events</td>
				</tr>
				<tr class="no-hover">
					<td colspan="7" class="no-padding">
						<div class="collapsible-sub-box" *tniCollapsibleIf="let colIf">
							<logger-report-event-list [sessionId]="session?.Id" [eventFilterObs]="eventFilterObs"></logger-report-event-list>
						</div>
					</td>
				</tr>
			</ng-container>
			</tbody>
		</table>
	