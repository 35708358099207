<div class="wrapper" [style.display]="_token.PropExpired ? 'none' : ''">
	<div class="content-wrapper" style="height: 100%;">
		<div style="height: 100%; overflow: auto;">
			<tni-error-message></tni-error-message>
			<router-outlet></router-outlet>
		</div>
	</div>

	<div class="side-sticky-content"></div>

</div>
<tni-portal-login *ngIf="_token.PropExpired"></tni-portal-login>
