<section class="content">
	<div class="row">
		<div class="col-md-12">
			<div class="box box-widget">
				<div class="box-header with-border">Session List</div>
				<div class="box-body">
					<div class="overlay-container top-right table-overlay">
						<logger-report-session-list [sessionFilterObs]="sessionFilterObs" [eventFilterObs]="eventFilterObs"></logger-report-session-list>
					</div>

				</div>
			</div>
		</div>
	</div>
</section>
