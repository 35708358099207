import {Component} from '@angular/core';
import {JsonWebToken} from '@tni/core';

@Component({
	selector: 'launch',
	template: `
		<div class="center-me"><button class="btn btn-primary btn-lg" (click)="launchPhone()">Launch Phone</button></div>
		<div class="center-me"><button *ngIf="showAgentViewButton" class="btn btn-default btn-sm" (click)="launchAgentPhone()">Launch Agent Phone</button></div>
	`,
	styles: [`
		:host {
			display: flex;
			align-content: center;
			flex-direction: column;
		}
		.center-me {
			display: flex;
			justify-content: center;
		}
		button {
			margin-top: 5vh;
		}
	`]
})
export class LaunchComponent {
	constructor(private _jwt: JsonWebToken) {}

	public showAgentViewButton: boolean;

	ngOnInit(): void {
		this.showAgentViewButton = this._jwt.CanAction('ViewAll', 4018);
	}

	public launchAgentPhone(): void {
		window.open(
			window.location.href + 'phone?view=agent',
			'phoneControl',
			'height=600,width=375,resizable,scrollbars=no,status=no,menubar=no,toolbar=no,location=no'
		);
	}

	public launchPhone(): void {
		console.log('hmmm');
		window.open(
			 'https://phone.x25.net/#/phone',
			'phoneControl',
			'height=600,width=375,resizable,scrollbars=no,status=no,menubar=no,toolbar=no,location=no'
		);
	}
}
