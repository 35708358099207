import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LoggerReportService} from './LoggerReportService';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {IClientEvent, LogLevel} from '@tni/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {ILoggerEventFilter} from './ILoggerEventFilter';

interface IClientEventExt extends IClientEvent {
	DetailsStr?: SafeHtml;
}

@Component({
	selector: 'logger-report-event-list',
	template: `
		<div (click)="refreshEventList()">Last updated: {{lastUpdateObs | async}}</div>
		<table class="table table-hover table-sortable minimal-margin-bottom table-striped-double table-fixed">
			<thead>
			<tr>
				<th class="time-column">Event Time</th>
				<th class="event-column">Event Name</th>
				<th class="level-column">Level</th>
				<th class="service-column">Service Name</th>
				<th class="url-column">URL</th>
				<th class="details-column">Details</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let clientEvent of eventListObs | async" tni-collapse-wrapper>
				<tr>
					<td class="time-column">{{clientEvent.EventTime}}</td>
					<td class="event-column">{{clientEvent.EventName}}</td>
					<td class="level-column">{{logLevel[clientEvent.LevelId]}}</td>
					<td class="service-column">{{clientEvent.ServiceName}}</td>
					<td class="url-column">{{clientEvent.ClientUrl}}</td>
					<td class="details-column clickable" tni-collapse-toggle>Show Details</td>
				</tr>
				<tr class="no-hover">
					<td colspan="6" class="no-padding">
						<div class="collapsible-sub-box" *tniCollapsibleIf="let colIf">
							<pre [innerHTML]="clientEvent.DetailsStr"></pre>
						</div>
					</td>
				</tr>
			</ng-container>
			</tbody>
		</table>
	`,
	styles: [`
		.time-column {
			width: 240px;
		}
		.event-column {
			width: 120px;
		}
		.level-column {
			width: 80px;
		}
		.service-column {
			width: 150px;
		}
		.details-column {
			width: 120px;
		}
		.collapsible-sub-box {
			width: 95%;
			margin-left: 4%;
		}
	`]
})
export class LoggerReportEventListComponent implements OnInit, OnDestroy {
	@Input('sessionId') sessionId: string;
	@Input('eventFilterObs') eventFilterObs: Observable<ILoggerEventFilter> = of(null);

	private _refreshEventListSubject: BehaviorSubject<void> = new BehaviorSubject(null);

	public eventListObs: Observable<IClientEventExt[]>;
	public lastUpdateObs: Observable<string>;

	public logLevel: typeof LogLevel = LogLevel;

	constructor(private _loggerReportService: LoggerReportService /* Provided in parent*/, private _sanitizer: DomSanitizer) {}

	ngOnInit(): void {
		this.eventListObs = this._refreshEventListSubject.asObservable().pipe(
			switchMap(() => this.eventFilterObs),
			switchMap((filter: ILoggerEventFilter) => {
				return this._loggerReportService.getEvents(this.sessionId, filter)
					.pipe(map((nts: IClientEvent[]) => {
						return nts.map((e: IClientEvent) => {
							let formatEv: IClientEventExt = {
								SessionId: e.SessionId,
								Id: e.Id,
								ClientSessionId: e.ClientSessionId,
								EventTime: e.EventTime,
								EventName: e.EventName,
								LevelId: e.LevelId,
								ServiceName: e.ServiceName,
								ClientEventId: e.ClientEventId,
								ClientUrl: e.ClientUrl,
								Details: e.Details
							};

							formatEv.DetailsStr = this._sanitizer.bypassSecurityTrustHtml(JSON.stringify(e.Details, null, '\t').replace(/(?:\\r)?\\n/g, '<br />'));

							return formatEv;
						});
					}));
			}),
			shareReplay(1)
		);
		this.lastUpdateObs = this.eventListObs.pipe(
			map(() => new Date().toISOString()),
			shareReplay(1)
		);
	}

	ngOnDestroy(): void {
		this._refreshEventListSubject.complete();
	}

	public refreshEventList: () => void = () => {
		this._refreshEventListSubject.next(null);
	}
}
