import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LoggerReportService} from './LoggerReportService';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {IClientSession} from '../../../../projects/@tni/core/src/lib/logger/clientSession.interface';
import {ILoggerSessionFilter} from './ILoggerSessionFilter';
import {ILoggerEventFilter} from './ILoggerEventFilter';

@Component({
	selector: 'logger-report-session-list',
	template: `
		<div (click)="refreshSessionList()">Last updated: {{lastUpdateObs | async}}</div>
		<table class="table table-hover table-sortable minimal-margin-bottom table-fixed table-striped-double">
			<thead>
			<tr>
				<th class="time-column">Create Time</th>
				<th class="id-column">Session ID</th>
				<th class="employee-column">Employee</th>
				<th class="ip-column">IP</th>
				<th class="ua-column">UA</th>
				<th class="client-id-column">Client Session ID</th>
				<th class="events-column">Events</th>
			</tr>
			</thead>
			<tbody>
			<ng-container *ngFor="let session of sessionListObs | async" tni-collapse-wrapper>
				<tr>
					<td class="time-column">{{session?.CreateTime}}</td>
					<td class="id-column">{{session?.Id}}</td>
					<td class="employee-column">{{(session?.EmployeeId | employee)}}, ({{session?.EmployeeId}})</td>
					<td class="ip-column">{{session?.ClientIp}}</td>
					<td class="ua-column">{{session?.UserAgent}}</td>
					<td class="client-id-column">{{session?.ClientSessionId}}</td>
					<td class="events-column clickable" tni-collapse-toggle>Show Events</td>
				</tr>
				<tr class="no-hover">
					<td colspan="7" class="no-padding">
						<div class="collapsible-sub-box" *tniCollapsibleIf="let colIf">
							<logger-report-event-list [sessionId]="session?.Id" [eventFilterObs]="eventFilterObs"></logger-report-event-list>
						</div>
					</td>
				</tr>
			</ng-container>
			</tbody>
		</table>
	`,
	styles: [`
		.time-column {
			width: 240px;
		}
		.id-column {
			width: 180px;
		}
		.employee-column {
			width: 240px;
		}
		.ip-column {
			width: 110px;
		}
		.client-id-column {
			width: 255px;
		}
		.events-column {
			width: 120px;
		}
		.collapsible-sub-box {
			width: 95%;
			margin-left: 4%;
		}
	`]
})
export class LoggerReportSessionListComponent implements OnInit, OnDestroy {
	@Input('sessionFilterObs') sessionFilterObs: Observable<ILoggerSessionFilter> = of(null);
	@Input('eventFilterObs') eventFilterObs: Observable<ILoggerEventFilter> = of(null);

	private _refreshSessionListSubject: BehaviorSubject<void> = new BehaviorSubject(null);

	public sessionListObs: Observable<IClientSession[]>;
	public lastUpdateObs: Observable<string>;

	constructor(private _loggerReportService: LoggerReportService /* Provided in parent*/) {}

	ngOnInit(): void {
		this.sessionListObs = this.sessionFilterObs.pipe(
			switchMap((f: ILoggerSessionFilter) => this._loggerReportService.getSessions(f)),
			shareReplay(1)
		);

		this.lastUpdateObs = this.sessionListObs.pipe(
			map(() => new Date().toISOString()),
			shareReplay(1)
		);
	}

	ngOnDestroy(): void {
		this._refreshSessionListSubject.complete();
	}

	public refreshSessionList: () => void = () => {
		this._refreshSessionListSubject.next(null);
	}
}
