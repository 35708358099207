/**
 * Created by Chris Coronado on 3/2/2018.
 */

import {catchError, debounceTime, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {
	DialerApiService,
	DialerComponent,
	DialerOption,
	ISessionDescriptor,
	PhoneControlService,
	SessionStatus
} from '@tni/phone';
import {IEngagement, IPhoneExt, JsonWebToken, PhoneExtService} from '@tni/core';
import {forkJoin, Observable, of, Subscription} from 'rxjs';


@Component(
	{
		templateUrl: 'PhoneTemplate.html',
		styles: [`
			.cover-background {
				width: 100vw;
				height: 100vh;
				background-color: #7c8d9c;
				position: fixed;
				top: 0;
				left: 0;
				z-index: 2000;
			}
			.phone-container {
				width: 100%;
				max-width: 500px;
				height: 100%;
				max-height: 700px;
				/*z-index: 2001;*/
				background-color: white;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				box-shadow: 0 0 10px 4px rgb(160, 160, 160);
			}
			@media (max-width: 767px) {
				.phone-container {
					transform: translateX(-50%);
				}
			}
		`],
		changeDetection: ChangeDetectionStrategy.OnPush,
		providers: [PhoneExtService]
	}
)
export class PhoneWidget implements OnInit, OnDestroy {
	constructor(private _router: Router,
				private _route: ActivatedRoute,
				private _phoneControlService: PhoneControlService,
				private _phoneExtService: PhoneExtService,
				private _jwt: JsonWebToken,
				private _dialerService: DialerApiService) {}

	public loadStatus: boolean;

	public phoneInfoObs: Observable<IPhoneExt>;

	public dialerOptionsObs: Observable<DialerOption[]>;
	public allEngagementHiddenDialerOptionsObs: Observable<DialerOption[]>;
	public engagementOptionsObs: Observable<IEngagement[]>;
	public showAdvancedObs: Observable<boolean>;
	public logWebRtcStatsObs: Observable<boolean>;

	public setLoadStatus(load: boolean): void {
		this.loadStatus = load;
	}

	private _phoneInfoSubscription: Subscription;

	private _activeSessionExistsObs: Observable<ISessionDescriptor>;

	ngOnInit(): void {
		let queryParamsObs: Observable<string | ParamMap> = this._route.queryParamMap.pipe(
			debounceTime(10),
			shareReplay(1)
		);

		queryParamsObs.pipe(take(1), map((params: ParamMap) => params.get('window') === 'any'))
			.subscribe((allowAny: boolean) => {
				if (allowAny) {return; }
				if (window.name != 'phoneControl') {
					this._router.navigate(['/']);
				}
			});

		this.phoneInfoObs = this._phoneExtService
			.GetExtByEmployeeId(this._jwt.GetEmployeeId())
			.pipe(map((e: IPhoneExt) => {
				if (!e) {
					throw new Error('Extension not configured.');
				}
				return e;
			}));

		let allowAdvanced: boolean = this._jwt.CanAction('ViewAll', 4018);
		let disableAdvancedParamObs: Observable<boolean> = queryParamsObs.pipe(map((params: ParamMap) => {
			let val: string = params.get('view');
			return val && val !== 'full';
		}));
		this.showAdvancedObs = disableAdvancedParamObs.pipe(map((d: boolean) => !!(!d && allowAdvanced)));

		this._activeSessionExistsObs = this._phoneControlService.sessionListStatusObs.pipe(
			map((list: ISessionDescriptor[]) => {
				return list && list.length && list
					.filter((l: ISessionDescriptor) => l.status !== SessionStatus.STATUS_NULL && l.status !== SessionStatus.STATUS_COMPLETED && l.status !== SessionStatus.STATUS_REJECTED)[0];
			}),
			shareReplay(1)
		);

		this.engagementOptionsObs = this._dialerService.GetEngagementOptions();

		this.dialerOptionsObs = this._dialerService.GetDialerOptions().pipe(catchError(() => {return of([]); }));
		this.allEngagementHiddenDialerOptionsObs = this._dialerService.GetInternalExtensionOptions().pipe(catchError(() => {return of([]); }));

		this.logWebRtcStatsObs = this.phoneInfoObs.pipe(map((p: IPhoneExt) => p.DebugMode));
	}

	ngOnDestroy(): void {
		if (this._phoneInfoSubscription) {this._phoneInfoSubscription.unsubscribe(); }
		this._phoneControlService.destroy();
	}


	@HostListener('window:beforeunload', ['$event'])
	doSomething(ev: BeforeUnloadEvent): void {
		this._activeSessionExistsObs.pipe(
			take(1),
			switchMap((a: ISessionDescriptor) => a ? this._phoneControlService.hasMediaLockObservable.pipe(map((s: boolean) => s ? a : null), take(1)) as Observable<ISessionDescriptor> : of(a) as Observable<ISessionDescriptor>)
		).subscribe((session: ISessionDescriptor) => {
			if (!!session) {ev.returnValue = 'Please hangup call before closing!'; }

		});
	}

	@HostListener('window:unload')
	onUnload(): void {
		this._activeSessionExistsObs.pipe(
				take(1),
				switchMap((a: ISessionDescriptor) => a ? this._phoneControlService.hasMediaLockObservable.pipe(map((s: boolean) => s ? a : null), take(1)) as Observable<ISessionDescriptor> : of(a) as Observable<ISessionDescriptor>)
			).subscribe((session: ISessionDescriptor) => {
				if (!session) {
					return;
				}

				this._phoneControlService.hangup(session.sessionId);

				this.ngOnDestroy();
			});
	}

}
