<!--<div class="cover-background"></div>-->
<div class="phone-container">
	<tni-phone-admin-template
	                          [controlOnly]="false"
	                          [autoRegister]="true"
	                          [phoneExtensionInfo]="phoneInfoObs | async"
	                          [dialerOptions]="dialerOptionsObs | async"
	                          [engagementOptions]="engagementOptionsObs | async"
	                          [showAdmin]="showAdvancedObs | async"
	                          [logWebRtcStats]="logWebRtcStatsObs | async"
	                          [allEngagementHiddenOptions]="allEngagementHiddenDialerOptionsObs | async"
	></tni-phone-admin-template>
</div>
